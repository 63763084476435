<template>
  <b-card>
    <div v-for="(item, index) in changelog.reverse()" :key="item.version">
      <hr v-if="index !== 0" />
      <h5>{{ item.version }}</h5>
      <ul>
        <li v-for="change in item.changes" :key="item.version + change">
          {{ change }}
        </li>
      </ul>
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  computed: {
    changelog() {
      return [
        {
          version: '0.1',
          changes: ['Created Changelog'],
        },
        {
          version: '0.2',
          changes: [
            'Μηχανισμός ανάρτησης εικόνας',
            'Δυναμικά φίλτρα οντοτήτων',
            'Μηχανισμός εξαγωγής σε CSV/XLS',
            'Μηχανισμός Datepicker',
            'Προσθήκη ενότητας παραγγελιών',
            'Προσθήκη ενότητας χρηστών',
            'Προσθήκη ενότητας ομάδας χρηστών',
            'Μηχανισμός απόδοσης δικααιωμάτων ανά ομάδα χρηστών',
            'JWT (JSON Web Tokens) token authentcation',
            'Λειτουργικότητα login / logout',
            'Μηχανισμός authorization per endpoint και δικαίωμα',
            'Μηχανισμός δυναμικής διαμόρφωσης προφίλ με βάση δικαιώματα',
          ],
        },
        {
          version: '0.3',
          changes: ['Επιλογές Προϊόντος'],
        },
        {
          version: '0.4',
          changes: ['Αναζήτηση στα ελληνικά', 'Responsiveness of Site'],
        },
        {
          version: '0.5',
          changes: [
            'Διατήρηση αγαπημένων σελιδοδεικτών',
            'Γραμμή αναζήτησης - padding',
          ],
        },
        {
          version: '0.6',
          changes: [
            'partner request authority fix',
            'partner request update status fix',
            'leads edit company field',
            'reset pass fixes',
            'product edit init hidden img fields',
            'Gallery auto remove wrong dimension images',
            'Gallery image delete placeholder',
            'Axios request progress bar',
          ],
        },
        {
          version: '0.7',
          changes: [
            'images autoupload functionality',
            'crop upload support',
            'Gallery image display fix',
            'customers list fix',
            'image change for new entity fix',
            'Gallery auto remove wrong dimension images',
            'Gallery image delete placeholder',
            'Axios request progress bar',
            'email appearance improvements',
            'reset password functionality',
          ],
        },
        {
          version: '0.7.1',
          changes: [
            'Added loading progress bar',
            'Disable action button while loading on all pages',
            'Avatar Icon bugfix',
            'Added IBAN validator',
            'Gallery uploader and dimensions validation',
            'Auto-crop images to correct size',
            'user request save fix',
          ],
        },
        {
          version: '0.7.2',
          changes: [
            'filter min max dates functionality',
            'Products price sort fix',
            'Localize calendar',
            'Account ajax notifications',
            'Bookmark locale issues',
            'Entity list dropdown table overflow',
            'customer date filters fix',
            'orders sorting fix',
            'order min date filter fix',
            'leads max date filter fix',
            'fix for empty objects',
            'Save app customization in localstorage',
            'filter end of day fix',
          ],
        },
        {
          version: '0.7.3',
          changes: [
            'find by ID 404 error fix',
            'force company on dropdown selections for non-admin users',
            'show Leads only of specific company for non-admin users',
            'show Customers only of specific company for non-admin users',
            'hardened endpoints for queries with entity ID',
            'increased pageable default size for notifications',
            'added company field for customers',
            'allow multiple companies assignment for customers & leads',
          ],
        },
        {
          version: '0.7.4',
          changes: [
            'Social Trade notification for unread messages',
            'Social Trade resolved various issues',
            'CSV export button fix',
            'Fixed notifications counter',
            'Fixed gallery upload validator',
            'Remove enable filter & button from products page',
            'Added disabled buttons when performing in entity page',
            'unread mgs on chat load fix',
            'added participated notice to Promotions',
            'product upload to e-shop with sale date fix',
            'partner requests rewrite for admins',
          ],
        },
        {
          version: '0.7.5',
          changes: [
            'Added pagination in all notifications page',
            'Notification links',
            'Promotions - disable participate button if participated',
            'announcement recipients fix',
            'promotion recipients fix',
            'announcement notifications fix',
            'promotion notifications fix',
            'promotion participation fix',
            'dashboard translations',
            'added layout preview sections',
          ],
        },
        {
          version: '0.7.6',
          changes: [
            'Force seller - supplier on product creation / edit',
            'Chat rights fix',
            'Account inage change fix',
            'Dashboard localization',
            'Featured Image B not showing in product edit fix',
            'Remove images from product gallery fix',
          ],
        },
        {
          version: '0.7.7',
          changes: [
            'Toast message on 400/404 error messages from API calls',
            'Added Partner products section',
            'Order company save fix',
            'Fixed account settings reactivity',
            'Set dark theme as default',
            'Added max input validator',
            'Set max validator for order number',
            'Orders edit decimals for price fix',
          ],
        },
        {
          version: '0.7.8',
          changes: [
            'FTP resources for product upload optimization',
            'Epilogue / General Description product upload fix',
            'Dark mode improvements for product presentation',
            'Ajax search fix for not published products',
            'Price for supplier tweak',
            'Added animations for single product page',
            'Improvements in e-shop menu for smaller screens',
          ],
        },
        {
          version: '0.7.9',
          changes: [
            'added view in e-shop btn',
            'extra actions completion msg',
            'extra actions completion responsivity',
            'Vimeo Upload example',
            'Display all product sections with error',
            'Product preview with content',
            'Not required price for supplier group role',
            'edited order required fields',
            'applied product sections in e-shop upload',
            'product hero zone slider functionality',
            'wishlist modal z-index fix',
          ],
        },
        {
          version: '0.8.0',
          changes: [
            'added newsletter wizard',
            'equal product spaces in e-shop',
            'Added entity credits functionality',
            'Announcements/Promotions eshop publish',
            'Supplier product price fields (target, min, max)',
            'Product extra radio buttons (customized, delivery ready, showroom)',
            'Update stock-price instead of product',
            'repeater field blank item fix',
            'image pulse on focus',
            'Changes to layout preview',
            'Category eshop sync improvements',
            'Removed Join categories',
            'responsive improvements in e-shop',
            'user find by email fixes',
            'prior deletion from e-shop for products',
            'fixed product description for mobiles in e-shop',
            'product thumbnails on slider design',
            'fixed product pagination in e-shop',
          ],
        },
        {
          version: '0.8.1',
          changes: [
            'fixed notification list negative countdown',
            'mobile table layout fix',
            'promotions sorting',
            'product descriptions editor options',
            'Preview images on newsletter wizard',
          ],
        },
        {
          version: '0.8.2',
          changes: [
            'remove from e-shop buttons fix',
            'product published status fix',
            'added users free text by email functionality',
            'escaping product content special characters before upload to eshop',
            'Added view product button in quickview',
            'Added user group validation if credits exceeded',
            'partner request tabs lazy loading',
            'added notification for new partner requests',
            'fetching fresh notifications with descending order',
            'notification support for specific users (besides companies)',
          ],
        },
        {
          version: '0.8.3',
          changes: [
            'promotions unparticipate action',
            'product image deletion fix',
            'sections forced by image fix',
            'bold removal on product preview focused items',
            'Added link on product notifications',
            'Added link on order notifications',
            'Added link on user request notifications',
            'Added seller filter on products list',
            'Added supplier filter on products list',
            'Added customized filter on products list',
            'Added delivery ready filter on products list',
            'Added showroom filter on products list',
            'Added published filter on products list',
            'Seller single choice on product edit',
            'Supplier single choice on product edit',
            'Added seller column products list',
            'Added supplier column products list',
            'Added product orign relation',
            'Not used rights clearance',
            'Added right labels translations',
            'Split partner products to supplier and seller list',
            'Added supplier filter on supplier products list',
            'Added seller filter on seller products list',
            'Preview product functionality on seller products list',
            'Partner products queries optimization',
            'Partner products inner link removal',
          ],
        },
        {
          version: '0.8.4',
          changes: [
            'User rights on products for companies which are both seller & supplier fix',
            'Product publish to eshop actions removed for suppliers',
            'dismiss fresh notifications upon read all',
            'added company stores entity',
            'Updated email content',
            'Update stock price check',
            'Added map radius functionality',
            'increased notifications per page to 25',
            'Option to set circle radius via input',
            'Added payment methods',
            'Added order payments',
            'Added company payments',
          ],
        },
        {
          version: '0.8.5',
          changes: [
            'Text wrap for entity titles',
            'Publish to e-shop rights validation fix',
            'New User save fix',
            'Search only by product title in e-shop',
            'Added working hours field in company entity',
            'Added publish companies to e-shop functionality',
          ],
        },
        {
          version: '0.8.6',
          changes: [
            'Woocommerce sellers/suppliers POST API call fix',
            'Company working hours save fix',
            'added Company delete from eshop endpoint',
            'added company extra action buttons on company list',
          ],
        },
        {
          version: '0.8.7',
          changes: [
            'product price edit for admin fix',
            'company layout fields population fix',
            'notification link when already on component fix',
            'promotions I participate filter addition',
          ],
        },
        {
          version: '0.8.8',
          changes: [
            'product price required for admin fix',
            'blog thumbnails on e-shop addition',
            'removed author from blog',
            'blog boxes on homepage improvements',
            'blog pagination fixes',
            'added print functionality for e-shop',
            'automatically generating a latin-only slug for products uploaded to e-shop',
            'Media library image cleanup',
            'Added product category banner image',
            'Added product category preview image',
            'Added designer banner position on product category pages',
            'Send seller taxonomy value to woocommerce API',
            'Send supplier taxonomy value to woocommerce API',
            'Added supplier link on product page',
            'Added seller button on product page',
          ],
        },
        {
          version: '0.8.9',
          changes: [
            'Eshop statistics dashboard beta',
            'API key authentication for eshop events',
            'Users stats endpoint addition',
            'Product views endpoint addition',
            'Online sales endpoint addition',
            'Eshop Category banner appearance for mobile fix',
            'Bookmark fixes',
            'dropdowns autocomplete fix',
            'datepicker disable browser autocomplete',
            'Password Message Fix',
            'Entity duplication in modals fix',
            'general css update for form fields',
          ],
        },
        {
          version: '0.9.0',
          changes: [
            'E-shop event by company query fix',
            'Decor Style entity addition',
            'Color entity addition',
            'Support for Decor Style sync with e-shop',
            'Support for Color sync with e-shop',
            'Decor Style field for admin only',
            'Remove product from e-shop even if not found',
            'Delete product from e-shop upon removal (instead of setting it to draft status)',
            'Set e-shop ID to null fix',
            'Sync decor styles to e-shop',
            'Sync colors to e-shop',
            'Created explore landing page',
            'Created explore inner page',
            'Added product options in e-shop',
            'Added newsletter import functionality in e-shop',
            'Added newsletter integration with checkout & registration forms',
            'Added sellers to product filters',
            'Added suppliers to product filters',
            'Added decoration style to product filters',
            'Added adoptive functionality to product filters',
          ],
        },
        {
          version: '0.9.1',
          changes: [
            'Product deletion fix',
            'Added Upload supplier functionality',
            'Added supplier preview eshop page button',
            'Added supplier eshop page layout',
            'Added default country and enabled on company insert',
            'Added default country and enabled on user insert',
            'Added default enabled on lead insert',
            'Update partner request status backend validation',
            'Update partner request extra actions fix',
            'Refetch table data on partner request status save',
            'New partner request validation for existing request between same users',
            'Lead companies field visible only to admin',
            'Lead company applied automatically for seller & supplier roles',
            'Password save on account settings fix',
            'Password validation on account settings tab',
            'Account settings fields translations',
            'User company change on account settings fix',
          ],
        },
        {
          version: '0.9.2',
          changes: [
            'Removed emails menu items',
            'Rearranged administration menu',
            'Added company filter on leads list',
            'Update supplier e-shop page fix',
            'Remove company from e-shop before entty deletion',
            'Partner request authority check for menu fix',
            'Added approved acton for declined partner requests',
            'Products package limit server side validation fix',
            'Leads package limit server side validation fix',
            'Users package limit server side validation fix',
            'Lead duplication fix',
            'Customer duplication fix',
            'User duplication fix',
            'Added alert box for active partner requests limit',
            'Added upgrade call to action button on my partner requests page',
            'Added company title on partner request lists',
            'Added company title on header',
            'Product content attributes before upload extra character escapes',
            'Partner products query fixes',
            'Entity store memory management optimization',
            'Fix access to partner product sell page',
            'Promotions inner page content loading fix',
          ],
        },
        {
          version: '0.9.3',
          changes: [
            'Added social share fuctionality on blog articles',
            'CSS improvement on prev next blog link',
            'Added social share fuctionality on product pages',
            'Added social share fuctionality on magazines',
            'User max products fix if unlimited in account',
            'Added all sellers A-Z page',
            'Added all sellers landing page with logos',
            'Added sync for product customized field',
            'Added sync for product deliveryReady field',
            'Added sync for product showroom field',
            'Added customized, showroom and deliveryReady filters in e-shop',
            'Togglw functionality on e-shop filters',
          ],
        },
        {
          version: '0.9.4',
          date: '2022-03-01',
          changes: [
            'Dependency upgrades',
            'Spring Boot upgrade to 2.6.x',
            'Database queries lazy loading speed optimization',
            'Unlock user functionality',
            'Update user email fix',
            'Added send reset password email functionality',
            'Admin Partner requests list loading fix',
            'Country list alphabetic order',
            'User request approval required fields fix',
            'User request redirect to list upon approval',
            'Allow admin to edit list and target prices',
            'Added company serve radius field',
            'Added company serve radius dynamic circle shape in map',
            'Improved spaces in automated emails',
            'Improved fonts in automated emails',
            'Insert leads by website API calls',
            'Store leads ID for Google Analytics usage',
            'Added translations',
          ],
        },
        {
          version: '0.9.5',
          date: '2022-03-02',
          changes: [
            'Product thumbnail on my products list',
            'Product thumbnail on my product partners lists',
            'Product save after duplication fix for published to e-shop products',
            'Keep product images & layout upon duplication',
            'Keep product images & layout upon seller choice to sell',
            'Global search results fix',
          ],
        },
        {
          version: '0.9.6',
          date: '2022-03-09',
          changes: [
            'Added auditing on post categories',
            'Added lead group entity',
            'Added lead group field on leads insert/edit',
            'Added lead group filter on leads',
            'Leads menu rearrange',
            'Select lead group on newsletter wizard',
            'Partners list fix on newsletter wizard',
            'Added promotions custom post type in e-shop',
            'Connected promotions to sellers and suppliers in e-shop',
            'Upload announcement to website functionality',
            'Remove announcement from website functionality',
            'View announcement in website functionality',
            'Update announcement in website functionality',
            'Switched linux core to AlmaLinux 8.5 (Arctic Sphynx)',
          ],
        },
        {
          version: '0.9.7',
          date: '2022-03-10',
          changes: [
            'Fixed some button links in eshop homepage',
            'Added popular products in eshop homepage',
            'Header wishlist icon fix',
            'E-shop mega menu custom design',
            'Order transaction save fix',
            'Removed uncategorized product category',
            'E-shop order to API automatic insert fix',
          ],
        },
        {
          version: '0.9.8',
          date: '2022-03-22',
          changes: [
            'Seller landing page',
            'Supplier landing page',
            'Custom carousel for sellers',
            'Hide prices with link for non-logged clients in e-shop',
            'Added restrictions to Google Maps API key',
            'Google Maps API async load',
            'Added subscribe to newsletter functionality on e-shop client registration',
            'Integration to Google Maps Places API',
            'Spring boot upgrade to 2.6.4',
            'Image upload rewrite - now all images all automatically uploaded to FTP',
            'Main parameters entity addition',
            'Badges entity addition',
            'Badges assignment to companies',
          ],
        },
        {
          version: '0.9.9',
          date: '2022-03-29',
          changes: [
            'Customer Email Verification mechanism',
            'Everypay integration',
            'Customer Email Verification with social login integration',
            'Seller page map improvements',
            'Seller page responsive design improvements',
            'Seller page map options (zoom, street view, full screen)',
            'Link support for seller contact info',
            'Set new logo',
            'Applied favicon/Andoid/iOS icons',
            'Sticky header improvements',
            'Instagram feed improvements',
            'E-shop footer redesign',
            'Blog embedding new design',
            'Products carousel embedding new design',
          ],
        },
        {
          version: '1.0.0',
          date: '2022-04-05',
          changes: [
            'Added cash on delivery payment method',
            'Extra fee mechanism for cash on delivery payment method',
            'Disable cash on delivery payment method for larger than 500 euro amounts',
            'Made seller address linkable',
            'Main parameters menu addition',
            'Added support for products with no stock',
            'Woocommerce integration for producta with no stock',
            'Added product Shipping Lead Days',
            'Dashboard logo rebranding',
            'Added company PDF catalogue field',
            'Remove map radius for suppliers',
            'Admin upgrade notice fix on partner requests',
            'Partner request columns redesign',
            'Partner request link to e-shop company page',
            'Added designer role',
            'Added design ideas entity',
            'Added thumbnail creation functionality on dashboard',
            'Spring Boot upgrade to 2.6.5',
            'Added installments support for companies',
            'Added extra content fields for companies',
            'Added extra image fields for companies',
          ],
        },
        {
          version: '1.0.1',
          date: '2022-04-12',
          changes: [
            'Company logo can now be added at entity insert',
            'Removed company gallery field',
            'Rearranged company account layout',
            'Company preview layout redesign',
            'Crop upload translation',
            'PDF upload improvements',
            'Supplier page improvements',
            'Publish seller to e-shop with new design functionality',
          ],
        },
        {
          version: '1.0.2',
          date: '2022-04-19',
          changes: [
            'Upgrade to Woocommerce 6.4.1',
            'Register/login page redesign',
            'Added filters collapse functionality',
            'Images migration from previous database schema',
            'Product images can now be added at entity insert',
            'Gallery image upload fix',
            'Empty photo display fix',
            'Added seller select product insert method screen',
            'Added category filter on my products page',
            'Added category filter on supplier products page',
            'Added category filter on seller products page',
            'Added all supplier products page',
            'Min max date validation on product filters fix',
            'Disable subcribe to newsletter popup on account page',
            'Added extra fields to registration page',
            'Address autocomplete mapping to structured fields mechanism',
            'Address company logo to corresponding entity list',
          ],
        },
        {
          version: '1.0.3',
          date: '2022-04-26',
          changes: [
            'Home page design improvements',
            'XL sidebar size addition',
            'File upload on entity insert fix',
            'Added image upload functionality for badges',
            'PNG image crop support',
            'Added dynamic badges on seller landing page through entity publication to e-shop',
            'Supplier column redesign, with logo and link to landing page',
            'Added Send Partner Request on all supplier products page',
            'Product details by id endpoint locked to seller, suppliers and partners',
            'Product notifications for sellers fix',
            'Fresh notifications filters fix',
            'Product with no quantity save fix',
            'Product search by seller query fix',
          ],
        },
        {
          version: '1.0.4',
          date: '2022-04-30',
          changes: [
            'Usergoup query lazy load optimization',
            'Product query speed optimization',
            'Product filter by seller fix',
            'Product details by id endpoint fix',
            'Added products unique inde based on SKU',
            'Set fixed height, center aligned vertically and applied overflow auto on product descriptions for desktop',
            'Publish supplier to e-shop with new design functionality',
            'Differentiated supplier partner products menu',
            'Select partner button navigation fix',
            'Provided seller selection for suppliers - supplier selection for sellers',
            'Excluded current partners from above lists',
            'Added Partner Browse usergroup right',
            'Send partner request from seller - suppliers selection lists mechanism',
            'Added product sell requests mechanism',
            'Added product sell requests rights',
            'E-shop header redesign with semi-transparent background',
            'Responsive design actions for header redesign',
            'Category banner height re-adjustment',
            'Page title redesign with semi-transparent background',
            'Admin can now assign product to multiple sellers',
            'Added support for no price products',
            'Added product multi grid design',
          ],
        },
        {
          version: '1.0.5',
          date: '2022-05-06',
          changes: [
            'Eshop - Animate anchor links functionality',
            'Eshop - Dropdowns custom uniform styling',
            'Eshop - Home category dropdown dynamic population',
            'Eshop - Save latitude & longtitude on checkout',
            'Eshop - Save latitude & longtitude on account registration',
            'Eshop - Set address cookies',
            'Eshop - Store locator addition',
          ],
        },
        {
          version: '1.0.5',
          date: '2022-05-10',
          changes: [
            'Eshop - Display logged user address',
            'Eshop - Address utility functions',
            'Eshop - Display address in header',
            'Eshop - Link address icon to register/login page',
            'Eshop - Autocomplete registartion address based on cookie',
            'Eshop - Navigation to seleted category on home address form',
            'Eshop - Display current address on category pages',
            'Eshop - Display seller info on product grid',
            'Eshop - Display seller logo on product grid',
            'Eshop - Added seller lat & lng fields in admin panel',
            'Eshop - Added distance calculator mechanism',
            'Eshop - Designer profile page beta preview',
          ],
        },
        {
          version: '1.0.6',
          date: '2022-05-17',
          changes: [
            'Eshop - Designer profile page completion',
            'Eshop - Explore landing page beta preview',
            'Eshop - Mega menu improvements for small laptop screens',
            'Eshop - Added decor style taxonomy',
            'Eshop - Added interior designer custom post type',
            'Backend - Switched sync mechanism to decor style taxonomy',
            'Backend - Added shipping zone locations sync',
            'Backend - Company pubish fix when google URL is empty',
            'Backend - Added shipping locations controllers & services',
            'Backend - Google Analytics Reporting service account integration',
            'Backend - Applied date range filter on dashboard statistics',
            'Backend - Added all supplier & all sellers lists endpoints for dropdowns',
            'MyPanel - Added shipping zone locations management',
            'MyPanel - Added default radius parameter',
            'MyPanel - Added company state field',
            'MyPanel - Added state filter to companies list',
            'MyPanel - Added state filter to browse suppliers list',
            'MyPanel - Added state filter to browse sellers list',
            'MyPanel - Added user group rights translations',
            'MyPanel - Added date range selection on dashboard',
            'MyPanel - Send partner request from browse products screen fix',
            'MyPanel - Added view in e-shop button on browse products screen',
          ],
        },
        {
          version: '1.0.7',
          date: '2022-05-20',
          changes: [
            'Eshop - Added banner field for decor styles',
            'Eshop - Added image field for decor styles',
            'Eshop - Added products field for portfolio',
            'Eshop - Added designer dropdown field for portfolio',
            'Eshop - Added designer link on portfolio inner page',
            'Eshop - Portfolio inner page design improvements',
            'Eshop - Portfolio inner page content - display products based on custom field',
            'Eshop - Homepage design changes',
            'Eshop - Font awesome library upgrade to 6',
            'Backend - Display product images in uploaded content fix',
            'Backend - Added perifereies from Kallikratis in DB',
            'Backend - Added perifereiakes enothtes from Kallikratis in DB',
            'Backend - Added dhmoi from Kallikratis in DB',
            'Backend - Added municipality entities, service & controller',
            'MyPanel - Added municipality management',
            'MyPanel - Added municipality field on companies edit',
          ],
        },
        {
          version: '1.0.8',
          date: '2022-05-23',
          changes: [
            'Backend - Company save municipality fix',
            'Backend - Edited default pagination size',
            'Backend - Product price entity fixes',
            'MyPanel - Company fields translations',
            'MyPanel - Added service coverage screen',
            'MyPanel - Added filters in service coverage screen',
            'MyPanel - Added search in service coverage screen',
            'MyPanel - Added export options in service coverage screen',
            'MyPanel - Added marker info window in service coverage screen',
            'MyPanel - Buttons inline actions design improvement on medium & small screens',
            'MyPanel - Removed variations tab',
            'MyPanel - Added product prices tab for admin and supplier',
          ],
        },
        {
          version: '1.0.9',
          date: '2022-05-25',
          changes: [
            'Eshop - Decor style inner page',
            'Eshop - Edited explore landing page on menu',
            'Eshop - GDPR cookie consent mechanism',
            'Eshop - Added cookies list',
            'Eshop - Submit seller contact form fix',
            'Eshop - Set unified border radius on buttons',
            'Eshop - Contact forms submit fix',
            'Eshop - Reduced header background opacity',
            'Eshop - Product quickview redesign',
            'Eshop - Spaces in seller & supplier page fix',
            'Eshop - Seller products section design improvements',
            'Eshop - Woocommerce upgrade to 6.5.1',
            'Eshop - WPBakery Page Builder upgrade to 6.9.0',
            'Eshop - Slider Revolution upgrade to 6.5.22',
            'Eshop - Seller attribute on corresponding products shortcode',
            'Eshop - Supplier attribute on corresponding products shortcode',
            'Eshop - Seller categories displayed as slider',
            'Eshop - Supplier categories displayed as slider',
            'Eshop - Products shortcode timeout fix',
            'Backend - Publish distinct seller categories for seller page',
            'Backend - Publish distinct supplier categories for supplier page',
            'Backend - Publish distinct seller moodboards for seller page',
            'Backend - Publish distinct supplier moodboards for supplier page',
            'Backend - Publish seller products for seller page',
            'MyPanel - Product fields translations',
            'MyPanel - Updated product extra actions for seller',
            'MyPanel - Company catalogue pdf upload fix',
            'MyPanel - Gallery upload fix for new products',
            'MyPanel - Products prices tab on insert fix',
          ],
        },
        {
          version: '1.1.0',
          date: '2022-05-27',
          changes: [
            'Eshop - Applied google fonts',
            'Eshop - Decor style inner page header fix',
            'Eshop - Added products of decor style on each inner page',
            'Eshop - Decor style attribute on corresponding products shortcode',
            'Eshop - Added moodboards of decor style on each inner page',
            'Eshop - Added products of same decor style on inner product page',
            'Eshop - Added moodboards of same decor style on each product page',
            'Eshop - Random algorithm for related moodboards',
            'Eshop - Random algorithm for same decor style products',
            'Eshop - Added Decor styles blocks',
            'Backend - Company entity save by seller role fix',
            'Backend - Company save by seller/supplier role security hardening',
            'Backend - Product prices unique contraint',
            'Backend - Product prices save for seller',
            'Backend - Decor styles sync',
            'Backend - Publish product decor styles along with product',
            'Backend - Added sell requests controllers & services',
            'Backend - Get active partners list fix',
            'Backend - Fetch suppliers fix',
            'Backend - Response for products with no supplier fix',
            'MyPanel - Product options save fix',
            'MyPanel - Product prices tab for seller',
            'MyPanel - Product prices tab lazy loading',
            'MyPanel - Categories dropdown shown with hierarchy',
            'MyPanel - Crop upload support for product options',
            'MyPanel - Seller select product from partner supplier fix',
            'MyPanel - Differentiated actions on browse products list, for partner and non-partner products',
            'MyPanel - Insert prices only by seller for products with supplier',
          ],
        },
        {
          version: '1.1.1',
          date: '2022-05-30',
          changes: [
            'Eshop - Migration to .com domain',
            'Eshop - Under construction page',
            'Eshop - Set Facebook social login app for new domain',
            'Eshop - Set LinkedIn social login app for new domain',
            'Eshop - Set Google social login app for new domain',
            'Backend - Migration to .com domain',
            'MyPanel - Product description field hidden',
          ],
        },
        {
          version: '1.1.2',
          date: '2022-05-31',
          changes: [
            'Eshop - Removed store & showroom filters',
            'Eshop - Show moodboards each product participates in product inner page',
            'Backend - Image paths on DB fix',
            'Backend - SMTP settings for automated emails improvements',
            'Backend - Added store to lead sources',
            'Backend - FTP upload exception handling',
            'MyPanel - Added Google rating field',
            'MyPanel - Added Facebook likes field',
          ],
        },
        {
          version: '1.1.3',
          date: '2022-06-01',
          changes: [
            'Eshop - Responsive design improvements on home page and explore pages',
            'Eshop - Category header redesign',
            'Eshop - Set default map marker for company pages',
            'Backend - Image upload rewrite',
            'Backend - Product upload fix for out of stock products',
            'Backend - Cardlink payment success endpoint',
            'Backend - Cardlink payment failure endpoint',
            'Backend - Cardlink payment digest generation',
            'Backend - Cardlink transactions save',
          ],
        },
        {
          version: '1.1.4',
          date: '2022-06-03',
          changes: [
            'Eshop - Changed default map marker for company pages',
            'Eshop - New logo in emails',
            'Backend - New logo in emails',
            'Backend - Company payments foreign key fix',
            'Backend - Added Shipping Lead Days for seller',
            'Backend - Handle product deletion by seller',
            'MyPanel - Real data on user payments menu',
            'MyPanel - Applied rights on user dropdown payments menu',
            'MyPanel - Applied rights on user dropdown subscription menu',
            'MyPanel - Order subscriptions by monthly amoun ascending',
            'MyPanel - Highlight correct plan on subscriptions page',
            'MyPanel - Added Google review count field',
            'MyPanel - Transferred reviews to separate tab for admin only',
            'MyPanel - Company payments tab for admin only',
            'MyPanel - Added validation for google rating field',
            'MyPanel - Removed product options tab',
            'MyPanel - Removed Shipping Lead Days for supplier',
            'MyPanel - Added Shipping Lead Days for seller',
            'MyPanel - Only admin & supplier can unpublish from e-shop',
            'MyPanel - Supplier publish company to e-shop fix',
            'MyPanel - Added Partnership Opportunities menu entry',
          ],
        },
        {
          version: '1.1.5',
          date: '2022-06-06',
          changes: [
            'Eshop - Changed default map marker size for company pages',
            'Eshop - Changed menu icons',
            'Eshop - Changed menu icons for dark mode',
            'Eshop - Changed header bg color for dark mode',
            'Backend - Auto update subscription upon company manual payment',
            'Backend - Auto update subscription cardlink payment',
            'MyPanel - Hidden Extra dashboard stats',
            'MyPanel - Added company name in invoices list',
            'MyPanel - Allow payment insert on invoices for admin',
            'MyPanel - Added subscription on company payment fields',
            'MyPanel - Added subscription type on company payment fields',
            'MyPanel - Set max date validation for custom payments',
          ],
        },
        {
          version: '1.1.6',
          date: '2022-06-08',
          changes: [
            'Eshop - Dark mode hidden',
            'Eshop - Interior designer inner page header fix',
            'Eshop - Changed seller popup title',
            'Eshop - Google font loading fix',
            'Eshop - Change address modal mechanism',
            'Eshop - Added live streaming appointment contact form',
            'Eshop - Added datetime picker field',
            'Eshop - Applied date/time validations for live streaming appointments',
            'Eshop - Added dynamic decor styles shortcode for interior designers',
            'Eshop - Added logo, banner & URL fields for supplier entity',
            'Eshop - Product grid design improvements (address & lines)',
            'Eshop - Tweaked address hierarchy - temp address overrides saved',
            'Eshop - Optimized nearest sellers query',
            'Backend - Upload facebook likes on partner publication',
            'Backend - Upload Google rating and count on partner publication',
            'Backend - Added live streaming appointment contact form functionality',
            'Backend - Send municipality to Cardlink as billCity',
            'Backend - Update seller lat, lang & radius on publication fix',
            'Backend - Publish seller logo on publication fix',
            'Backend - Publish supplier logo on publication fix',
          ],
        },
        {
          version: '1.1.7',
          date: '2022-06-08',
          changes: [
            'Eshop - Product extra meta save fix',
            'Eshop - Added product grid level 1',
            'Eshop - Added product grid level 2',
            'Eshop - Added decor styles on product grid level 1',
            'Eshop - Slider mechanism for grid level 1',
            'Eshop - Removed autoplay on seller categories',
            'Eshop - Removed buy from shop button on product page',
            'Eshop - Removed add to cart button on product page',
            'Eshop - Search adress button disabled on some occassions fix',
            'Eshop - Submenu z-index fix on seller page',
            'Eshop - Double wishlist button on single product page fix',
            'Backend - Removed product entry features',
          ],
        },
        {
          version: '1.1.8',
          date: '2022-06-13',
          changes: [
            'Eshop - Add live streaming form submissions to leads',
            'Eshop - Assign lead to correct company fix',
            'Eshop - Added Cyprus to shipping destinations',
            'Eshop - Dynamic category banner in grid',
            'Eshop - Removed login text in grid top when user logged in',
            'Eshop - Replaced category images in slider for sellers',
            'Eshop - Replaced category images in slider for suppliers',
            'Eshop - Added extra seller term meta fields',
            'Eshop - Added extra supplier term meta fields',
            'Eshop - Send email from contact form to corresponding seller fix',
            'Backend - Added Cyprus to shipping destinations',
            'Backend - Allow product reassigment to seller after deletion',
            'Backend - Improvement for out of stock products',
            'Backend - Syncing extra seller term meta fields',
            'Backend - Syncing extra supplier term meta fields',
            'Backend - Apply lead from contact form to corresponding seller fix',
            'MyPanel - Announcements image upload fix',
            'MyPanel - Crop upload modal enlargement',
            'MyPanel - Min radius validation fix',
            'MyPanel - Max radius validation fix',
            'MyPanel - Map input radius change value fix',
          ],
        },
        {
          version: '1.1.9',
          date: '2022-06-16',
          changes: [
            'Backend - Announcements publish to e-shop fix',
            'Backend - Added cover area addition & deletion rights',
            'MyPanel - Crop upload preview fix',
            'MyPanel - Crop upload zoom improvement',
            'MyPanel - Allow municipality addition',
            'MyPanel - Allow municipality deletion',
          ],
        },
        {
          version: '1.2.0',
          date: '2022-06-20',
          changes: [
            'Eshop - Added designers list shortcode',
            'Eshop - Show only supplier partners on seller landing page',
            'Eshop - Show only seller partners on supplier landing page',
            'Eshop - Letter spacing improvement in headings',
            'Eshop - Removed general price for single product page',
            'Eshop - Seller display price fix for single product page',
            'Eshop - Showroom query fix for 1st grid products',
            'Backend - Added VAT general parameter',
            'Backend - Automatically adding VAT rate to cardlink payments',
            'Backend - Receive device categories from Google Analytics API',
            'Backend - Sync seller partners with e-shop',
            'Backend - Sync supplier partners with e-shop',
            'Backend - Publish to eshop showroom fix for multiple sellers',
            'Backend - Publish to eshop price fix for multiple sellers',
            'MyPanel - Added partner store views in data layer',
            'MyPanel - Added average session duration in data layer',
            'MyPanel - Added device category pie chart in data layer',
          ],
        },
        {
          version: '1.2.1',
          date: '2022-06-21',
          changes: [
            'Eshop - Fix seller price on grid',
            'Eshop - Exclude products on same grid level',
            'Eshop - Exclude products from 3rd grid query fix',
            'Eshop - Added seller param filter',
            'Eshop - Seller categories point to specific seller products',
            'Eshop - Added add to cart button on single product page',
            'Eshop - Seller row design improvements',
            'Eshop - Display seller info in cart item',
            'Eshop - Add seller info to woocommerce order info in Woo admin panel',
            'Eshop - Add seller info to woocommerce emails',
            'Eshop - Manually send order data to backend fix',
            'Eshop - Display panel ID after successful order submission to backend API',
            'Backend - Save product seller by admin fix',
            'Backend - E-shop API order store fixes',
          ],
        },
        {
          version: '1.2.2',
          date: '2022-06-23',
          changes: [
            'Eshop - 404 error on subcategory selection in filters fix',
            'Eshop - Reverse print of regular and sale price on single product page fix',
            'Eshop - Everypay test card acceptance fix',
            'Eshop - Removed installments for Everypay payment method',
            'Eshop - Buy from shop URL fix',
            'Eshop - Products in first grid appearing also on second on some rare occasions fix',
            'Eshop - Removed products from decor style inner page',
            'Backend - Seller address URL fix',
            'Backend - Replaced seller live streaming contact form',
          ],
        },
        {
          version: '1.2.3',
          date: '2022-06-25',
          changes: [
            'Eshop - Send order data paid with everypay integration to backend',
            'Eshop - Add to cart button not shown for not logged in users in single product page',
            'Eshop - Removed price range filter for not logged in users in grid',
            'Backend - Order products filtering for seller',
          ],
        },
        {
          version: '1.2.4',
          date: '2022-07-06',
          changes: [
            'MyPanel - Abolished GR country code restriction for Google maps Geocoder',
          ],
        },
        {
          version: '1.2.5',
          date: '2022-07-22',
          changes: [
            'Eshop - Customized filter fix',
            'Eshop - Delivery ready filter fix',
            'Eshop - Nearest seller on grid fix',
            'Eshop - Google API loading fix',
            'Eshop - Facebook OAuth fix',
            'Eshop - Grid slider selection fix',
            'Eshop - Removed multiselect on category filter',
            'Eshop - Removed adoptive filter on category filter selections',
            'Eshop - Added filters on mobile',
            'Eshop - Single category selection filter on mobile',
            'Eshop - Menu appearance on blog fix',
            'Eshop - Global search fixes',
            'Backend - Hidden product after not fulfilled sell request',
            'Backend - Removed add to join list checkbox',
            'Backend - Email sender fix',
          ],
        },
        {
          version: '1.2.6',
          date: '2022-07-28',
          changes: [
            'Eshop - Display all products on 3rd grid',
            'Eshop - Do not display 1st & 2nd grid when specific seller category is selected',
            'Backend - Improved email logo design',
            'Backend - Upgrade to Spring Boot 2.7.1',
            'Backend - Large messages on chats fix',
            'MyPanel - Upgrade subscription button disappearance fix',
            'MyPanel - Product screen insert / update layout redesign',
            'MyPanel - Crop upload modal redesign',
            'MyPanel - Gallery functionality improvements',
            'MyPanel - Product screen disabled save button on some occasions fix',
            'MyPanel - Added usergroup on company edit screen for admins',
            'MyPanel - Force price insert for seller role',
          ],
        },
        {
          version: '1.2.7',
          date: '2022-07-30',
          changes: [
            'Eshop - Updated 3rd grid title',
            'Eshop - Create seller using EveryPay API',
            'Eshop - Split payments using EveryPay API',
            'Backend - Empty columns on product layout generation for e-shop on some occasions',
            'Backend - Hidden product after not fulfilled sell request fix correction',
            'Backend - Start subscription date from payment date when it has expired',
            'Backend - Updated email templates',
            'Backend - Added extra fields to seller objects for sync with e-shop',
            'Backend - Added unique constraint to synced colors',
            'Backend - E-shop sync endpoint 500 error fix',
            'MyPanel - Mobile field made required for users',
            'MyPanel - Edit product layout fix for supplier',
            'MyPanel - Updated terms & conditions link',
            'MyPanel - Upgrade subscription button disappearance fix correction',
          ],
        },
        {
          version: '1.2.8',
          date: '2022-09-14',
          changes: [
            'Backend - Spring Boot upgrade to 2.7.3',
            'Backend - Added revenue per month endpoint',
            'Backend - Added revenue per year endpoint',
            'Backend - Added platform popular products endpoint',
            'Backend - Added user popular products endpoint',
            'Backend - Added platform popular categories endpoint',
            'Backend - Added platform users per city dimension endpoint',
            'Backend - Supplier missing row on landing page fix',
            'MyPanel - npm modules updates',
            'MyPanel - Partner Products menu entry rename',
            'MyPanel - Added price formatter',
            'MyPanel - Orders columns & fields rearrange',
            'MyPanel - Added revenue per month graph',
            'MyPanel - Added revenue year filter',
            'MyPanel - Added platform popular categories table in dashboard',
            'MyPanel - Added user popular products table in dashboard',
            'MyPanel - Added platform popular per city dimension table in dashboard',
            'MyPanel - Rearranged supplier layout form',
            'MyPanel - Added supplier extra image field',
          ],
        },
        {
          version: '1.2.9',
          date: '2022-09-21',
          changes: [
            'MyPanel - supplier edit product layout fix',
            'MyPanel - allow decimals on subscription prices',
          ],
        },
        {
          version: '1.2.10',
          date: '2022-12-22',
          changes: [
            'Eshop - Added Color filter for products',
            'Eshop - Added Seller filter for products as Brands',
            'Eshop - Added Attributes and Sub-Attributes for products',
            'Eshop - Updated Category filter UI for products',
            'Eshop - Updated product page with three separate views with see more button',
            'Eshop - Updated main menu design',
            'Eshop - Added option for adding the Best Sellers, New Arrivals and Featured products along with the corresponding images in the main menu',
            'Eshop - Added option to send message to seller',
            'Eshop - Added option to chat with seller',
            'Eshop - Added option to appoint a meeting with seller',
            'Eshop - Product Filter : Available in store for display',
            'Eshop - Product Filter : Shipping Days',
            'Eshop - Product Filter : Payment Installments',
            'Eshop - Added Categories in menu',
            'Eshop - Added Sub-Categories in category page',
            'Eshop - Updated search by product tags',
            'Backend - Added feature for updating seller/supplier details with video chat links for tools like whatsapp, viber and skype',
            'Backend - Added platform for creating Product Tags',
            'Backend - Added feature for tag publish and unpublish (publish on tag creation and unpublish on tag deletion)',
            'Backend - Added platform for adding Product Tags for each product',
            'Backend - Added platform for adding Color for each product',
            'Backend - Added feature for admins to add logo for the products which has no supplier',
            'Backend - Added platform for adding Category, Sub-Category, Attribute and Sub-Attribute for each product',
            'Backend - Updated create Category feature to handle the creation of Sub-Category, Attribute and Sub-Attribute',
            'Backend - Updated category search endpoint to filter categories based on type (Category, Sub-Category, Attribute and Sub-Attribute)',
            'Backend - Added feature for Category publish and unpublish (publish on category creation and unpublish on category deletion)',
            'Backend - Added Best seller option for products',
            'Backend - Added Show as Brand option in company',
            'MyPanel - Added feature to update seller info with Social Media links',
            'MyPanel - Show as Brand Name checkbox for Supplier in Companies',
            'MyPanel - Product Categories page : Table-listing in tabs - Product Categories, Product Sub-Categories, Product Attributes, Product Sub-Attributes',
            'Mypanel - Product Categories page : Option to Add, Edit, Delete, Duplicate - Product Categories, Product Sub-Categories, Product Attributes, Product Sub-Attributes',
            'MyPanel - Added Categories tab in Product page to associate Category, Sub-category, Attribute, Sub-Attribute to a product',
            'MyPanel - Added Product Tags for products',
            'MyPanel - Added Best seller for products',
            'MyPanel - Added Supplier Logo for products',
            'MyPanel - Added logo for those products which has no supplier',
            'MyPanel - Added Product Tags UI',
            'MyPanel - Added option to Add, Edit and Delete Products Tags for products',
          ],
        },
        {
          version: '1.3.0',
          date: '2023-04-05',
          changes: [
          'MyPanel - Added feature for sending pdf files through chat tool',
          'MyPanel - Added signal to display when a new message is received in the chat tool',
          'MyPanel - Show maximum and minimum price of the product when a seller adopts the product',
          'MyPanel - Added log page for admin',
          'MyPanel - Added My Suppliers tab for sellers to see the Suppliers that are active partners as companies',
          'MyPanel - Added notifications for seller, supplier and admin',
          'MyPanel - Added ‘Partnership Opportunities’ tab for sellers to show Products and Companies',
          'MyPanel - Added ‘Partnership Opportunities’ tab for suppliers to show Companies',
          'MyPanel - Show sellers as companies under the ‘Active Partnership’ menu for suppliers',
          'MyPanel - Added filters - location, category, name and email in Partnership Opportunities menu for supplier',
          'MyPanel - Removed Customers and orders menu for supplier',
          'MyPanel - Added table to show top five areas the end users are coming from and table to show most popular areas the users who visited the microsites are coming from',
          'MyPanel - Show total user visited count for the entire platform, total views of microsites in Dashboard',
          'MyPanel - Added table to show top areas from which the end users that saw the Seller’s or Supplier’s microsite came from for Seller and Supplier respectively',
          'MyPanel - Show count of - users that have visited products for each seller, users that have visited microsite for each seller, online sales per seller',
          'MyPanel - Added pic chart for Seller to show the clicks count of -  Google Maps destination button, Create an appointment function, Google Maps location button, Phone call function',
          'MyPanel - Added product table for Seller with products listing and supplier column',
          'MyPanel - Added table for Supplier to show users of the products that the supplier owns and sells through other sellers in the platform',
          'MyPanel - Show count of - users that have visited products for each seller, users that have visited microsite for each supplier, online sales per supplier',
          'MyPanel - Show average time spent for users visiting the products, for each Supplier',
          'MyPanel - Added email notification for admin, when a new user creates an account on MyPanel',
          'MyPanel - Added functionality for users to send partner requests, even after termination or deletion',

          ],
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
h5 {
  margin: 1rem 0;
}

ul {
  padding: 2rem;
  background-color: #f9fafb;
  border-radius: 8px;
  list-style-type: '\2013';
  text-indent: 5px;
}

.dark-layout ul {
  background-color: #161d31;
}
</style>
